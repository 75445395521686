export default [
    {
        name: 'Home',
        path: '/',
        component: () => import('../views/home/Index.vue')
    },
    {
        name: 'MostRead',
        path: '/books/most-read',
        component: () => import('../views/book/MostRead.vue')
    },
    {
        name: 'LatestBooks',
        path: '/latest-books',
        component: () => import('../views/book/Latest.vue')
    },
    {
        name: 'SectionBooks',
        path: '/sections/:sectionCode',
        component: () => import('../views/book/Section.vue')
    },
    {
        name: 'GenreBooks',
        path: '/genres/:genreId',
        component: () => import('../views/book/Genre.vue')
    },
    {
        name: 'HoldingBooks',
        path: '/holding-books',
        component: () => import('../views/book/Holding.vue')
    },
    {
        name: 'BookDetail',
        path: '/books/:id',
        component: () => import('../views/book/View.vue'),
    },
    {
        name: 'LiquidationBooks',
        path: '/liquidation-books',
        component: () => import('../views/book/Liquidation.vue')
    },
    {
        name: 'ShoppingCart',
        path: '/shopping-cart',
        component: () => import('../views/shopping-cart/Index.vue'),
    },
    {
        name: 'OrderHistory',
        path: '/order-history',
        component: () => import('../views/order/History.vue'),
    },
    {
        name: 'OrderSuccessful',
        path: '/order-successful',
        component: () => import('../views/order/OrderSuccessful.vue'),
    },
    /*  {
        name: 'registerLibraryCard',
        path: '/members/request-a-library-card',
        component: () => import('../views/library-card/RegisterLibraryCard.vue'),
      },

      {
        name: 'libraryCardInfo',
        path: '/library-card-info',
        component: () => import('../views/library-card/LibraryCardInfo.vue'),
        meta: {requiresAuth: true},
      },
      {
        name: 'requestBook',
        path: '/request-book/:status',
        component: () => import('../views/request-book/ListRequestBook.vue'),
        meta: {requiresAuth: true},
      },*/
    {
        name: 'Category',
        path: '/category/:id',
        component: () => import('../views/post/Index.vue'),
    },
    {
        name: 'Guide',
        path: '/guide/:id',
        component: () => import('../views/post/Guide.vue'),
    },
    {
        name: 'Post',
        path: '/posts/:id',
        component: () => import('../views/post/View.vue'),
    },
    {
        name: 'Profile',
        path: '/profile',
        component: () => import('../views/profile/Index.vue')
    },
    {
        name: 'HoldingBook',
        path: '/member/:id/holding-books',
        component: () => import('../views/member/HoldingBook.vue')
    },
    {
        name: 'RegistrationForm',
        path: '/member/register',
        component: () => import('../views/member/RegistrationForm.vue')
    },
    {
        path: "/member/request-a-library-card",
        name: "RequestALibraryCard",
        component: () => import("../views/library-card/Request.vue")
    },
    {
        name: 'RegistrationSuccessful',
        path: '/member/registration-successful',
        component: () => import('../views/member/RegistrationSuccessful.vue'),
        //meta: {requiresAuth: true},
    },
    {
        name: 'EBooks',
        path: '/e-books',
        component: () => import('../views/e-book/Index.vue'),
    },
    {
        name: 'EBookDetail',
        path: '/e-books/:id',
        component: () => import('../views/e-book/View.vue'),
    },
/*    {
        name: 'Exams',
        path: '/exams',
        component: () => import('../views/exam/Index.vue'),
    },*/
];
