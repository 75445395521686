import simpleAcl from '../../middleware/simpleAcl'

export default [
  {
    path: '/admin/categories',
    name: 'AdminCategory',
    component: () => import("../../../views/dashboard/Router.vue"),
    meta: {
      middleware: [
        simpleAcl
      ]
    },
    children: [
      {
        path: '',
        name: 'IndexCategory',
        component: () => import("../../../views/dashboard/category/Index.vue"),
      },
      {
        path: 'create',
        name: 'CreateCategory',
        component: () => import("../../../views/dashboard/category/Add.vue"),
      },
      {
        path: 'edit/:id',
        name: 'EditCategory',
        component: () => import("../../../views/dashboard/category/Edit.vue")
      },
    ],
  },
];
