import {defineStore} from 'pinia'
import apiUrl from "@/configs/apiUrl";
import service from "@/services/service";

export const commonStore = defineStore({
  namespaced: true,
  id: 'common',
  state: () => ({
    isCallingApi: false,
    error_code: null,
    error: null,
    messageSuccess: '',
    errorMessages: '',
  }),
  actions: {
    async getConstants(url = null, params) {
      if (!url) {
        url = apiUrl.COMMON.constants;
      }
      return await service.get(url, params);
    },
  },

  getters: {
    getIsCallApi: (state) => state.isCallingApi,
    getMessageSuccess: (state) => {
      return state.messageSuccess
    },
    getMessageErr: (state) => {
      return state.errorMessages
    },
  },
})
