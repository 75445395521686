export default {
    perPage: [10, 20, 30, 50],
    defaultPerPage: 20,
    booksPerPage: 24,
    defaultFormatDate: 'dd-MM-yyyy',
    defaultFormatDatetime: 'dd-MM-yyyy HH:mm',
    ROOT: "root",
    ADMIN: "admin",
    STAFF: "staff",
    MEMBER: "member",
    CREATE: "create",
    EDIT: "edit",
    UPDATE: "update",
    HAND_OVER: "hand-over",
    APPROVE: "approve",
    REFUSE: "refuse",
    CLOSED: "closed",
    DEFAULT_PAGE: 1,
    PAGE_SIZE: 50,
    PAGE_BOOK_LIST_SIZE: 48,
    FIRST_ELEMENT: 0,
    GREEN_COLOR: '#198754',
    BLUE_COLOR: '#0d6efd',
    IS_LIQUIDATION: 1,
    IS_NOT_LIQUIDATION: 0,
    DELIVER_CANCEL: -1,
    DELIVER_CREATE: 0,
    DELIVERING: 1,
    DELIVERED: 2,
    WEEKDAYS: {
        'monday': 'Thứ 2',
        'tuesday': 'Thứ 3',
        'wednesday': 'Thứ 4',
        'thursday': 'Thứ 5',
        'friday': 'Thứ 6',
        'saturday': 'Thứ 7',
        'sunday': 'Chủ nhật',
    },
    LOAN_STATUS_OPTIONS: {
        PENDING: 0,
        REJECTED: 1,
        MAKE_AN_APPOINTMENT: 2,
        ON_LOAN: 3,
        COMPLETED: 4,
    },
    BOOK_STATUS_OPTIONS: {
        AVAILABLE: 0,
        LOST: 1,
        ON_LOAN: 2,
        FOR_SALE: 3,
        NONE_AVAILABLE: 4,
        SOLD: 5,
    },
    ORDER_STATUS_OPTIONS: {
        PENDING: 0,
        AWAITING_PICKUP: 1,
        AWAITING_SHIPMENT: 2,
        COMPLETED: 3,
        CANCELLED: 4,
    },
};

export const REQUEST_STATUS_MAP = {
    "create": 0,
    "hand-over": 1,
    "approve": 2,
    "refuse": 3,
    "closed": 4,
}
export const REQUESTING = 'requesting';
export const HANDOVERING = 'handovering';
export const RETURNED = 'returned';

export const STORAGE_BASE_INFO = 'baseInfo';
export const STORAGE_AREA_INFO = 'areaInfo';
