import simpleAcl from '../../middleware/simpleAcl'

export default [
  {
    path: '/admin/standard-book-sizes',
    name: 'StandardBookSize',
    component: () => import("../../../views/dashboard/Router.vue"),
    meta: {
      middleware: [
        simpleAcl
      ]
    },
    children: [
      {
        path: '',
        name: 'IndexStandardBookSize',
        component: () => import("../../../views/dashboard/standard-book-size/Index.vue"),
      },
      {
        path: 'create',
        name: 'CreateStandardBookSize',
        component: () => import("../../../views/dashboard/standard-book-size/Add.vue"),
      },
      {
        path: 'edit/:id',
        name: 'EditStandardBookSize',
        component: () => import("../../../views/dashboard/standard-book-size/Edit.vue")
      },
    ],
  },
];
