import simpleAcl from '../../middleware/simpleAcl'

export default [
  {
    path: '/admin/sections',
    name: 'Section',
    component: () => import("../../../views/dashboard/Router.vue"),
    meta: {
      middleware: [
        simpleAcl
      ]
    },
    children: [
      {
        path: '',
        name: 'IndexSection',
        component: () => import("../../../views/dashboard/section/Index.vue"),
      },
      {
        path: 'create',
        name: 'CreateSection',
        component: () => import("../../../views/dashboard/section/Add.vue"),
      },
      {
        path: 'edit/:id',
        name: 'EditSection',
        component: () => import("../../../views/dashboard/section/Edit.vue")
      },
    ],
  },
];
