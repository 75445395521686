<template>
  <component :is="layoutComponent"></component>
  <notifications/>
</template>

<script>
    import {defineAsyncComponent, shallowRef} from 'vue'
    import {watch} from '@vue/runtime-core'
    import {useRoute} from 'vue-router'

    export default {
        name: 'App',
        components: {},
        setup() {
            const route = useRoute()
            const layoutComponent = shallowRef();
            layoutComponent.value = 'Loading';
            watch(
                () => route.fullPath.includes('admin') ? 'Admin' : "Default",
                layout => {
                    layoutComponent.value = defineAsyncComponent(() =>
                        import(`./views/layouts/${layout}.vue`),
                    )
                },
                {immediate: true},
            )
            return {layoutComponent}
        }
    }
</script>


