import simpleAcl from '../../middleware/simpleAcl'

export default [
  {
    path: '/admin/users',
    name: 'User',
    component: () => import("../../../views/dashboard/Router.vue"),
    meta: {
      middleware: [
        simpleAcl
      ]
    },
    children: [
      {
        path: '',
        name: 'IndexUser',
        component: () => import("../../../views/dashboard/user/Index.vue"),
      },
      {
        path: 'create',
        name: 'CreateUser',
        component: () => import("../../../views/dashboard/user/Add.vue"),
      },
      {
        path: 'edit/:id',
        name: 'EditUser',
        component: () => import("../../../views/dashboard/user/Edit.vue")
      },
    ],
  },
];
