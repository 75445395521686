import simpleAcl from '../../middleware/simpleAcl'

export default [
  {
    path: '/admin/bookcases',
    name: 'Bookcase',
    component: () => import("../../../views/dashboard/Router.vue"),
    meta: {
      middleware: [
        simpleAcl
      ]
    },
    children: [
      {
        path: '',
        name: 'IndexBookcase',
        component: () => import("../../../views/dashboard/bookcase/Index.vue"),
      },
      {
        path: 'create',
        name: 'CreateBookcase',
        component: () => import("../../../views/dashboard/bookcase/Add.vue"),
      },
      {
        path: 'edit/:id',
        name: 'EditBookcase',
        component: () => import("../../../views/dashboard/bookcase/Edit.vue")
      },
    ],
  },
];
