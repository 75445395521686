import simpleAcl from '../../middleware/simpleAcl'

export default [
  {
    path: '/admin/members',
    name: 'Member',
    component: () => import("../../../views/dashboard/Router.vue"),
    meta: {
      middleware: [
        simpleAcl
      ]
    },
    children: [
      {
        path: '',
        name: 'IndexMember',
        component: () => import("../../../views/dashboard/member/Index.vue"),
      },
      {
        path: 'create',
        name: 'CreateMember',
        component: () => import("../../../views/dashboard/member/Add.vue"),
      },
      {
        path: 'edit/:id',
        name: 'EditMember',
        component: () => import("../../../views/dashboard/member/Edit.vue")
      },
    ],
  },
];
