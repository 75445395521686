import {createRouter, createWebHistory} from "vue-router";
import {authStore} from '../store/modules/authStore'

import authorRoutes from './modules/dashboard/author'
import bookRoutes from './modules/dashboard/book'
import eBookRoutes from './modules/dashboard/eBook'
import examRoutes from './modules/dashboard/exam'
import bookcaseRoutes from './modules/dashboard/bookcase'
import bookcaseFloorRoutes from './modules/dashboard/bookcaseFloor'
import categoryRoutes from './modules/dashboard/category'
import classNameRoutes from './modules/dashboard/className'
import deviceRoutes from './modules/dashboard/device'
import deviceTypeRoutes from './modules/dashboard/deviceType'
import genreRoutes from "./modules/dashboard/genre";
import libraryCardRoutes from "./modules/dashboard/libraryCard";
import liquidationRoutes from "./modules/dashboard/liquidation";
import loanRequestRoutes from "./modules/dashboard/loanRequest";
import memberRoutes from "./modules/dashboard/member";
import orderRoutes from "./modules/dashboard/order";
import postRoutes from "./modules/dashboard/post";
import publisherRoutes from "./modules/dashboard/publisher";
import quickResponseCodeRoutes from "./modules/dashboard/quickResponseCode";
import sectionRoutes from "./modules/dashboard/section";
import staffPositionRoutes from './modules/dashboard/staffPosition'
import standardBookSizeRoutes from "./modules/dashboard/standardBookSize";
import statisticRoutes from './modules/dashboard/statistic'
import subjectRoutes from "./modules/dashboard/subject";
import userRoutes from './modules/dashboard/user'

import pages from "./pages";

import simpleAcl from './middleware/simpleAcl'

const routes = [
    ...authorRoutes,
    ...bookRoutes,
    ...examRoutes,
    ...eBookRoutes,
    ...bookcaseRoutes,
    ...bookcaseFloorRoutes,
    ...categoryRoutes,
    ...classNameRoutes,
    ...deviceRoutes,
    ...deviceTypeRoutes,
    ...genreRoutes,
    ...libraryCardRoutes,
    ...liquidationRoutes,
    ...loanRequestRoutes,
    ...memberRoutes,
    ...orderRoutes,
    ...postRoutes,
    ...publisherRoutes,
    ...quickResponseCodeRoutes,
    ...sectionRoutes,
    ...standardBookSizeRoutes,
    ...statisticRoutes,
    ...subjectRoutes,
    ...userRoutes,
    ...staffPositionRoutes,
    ...pages,
    {
        path: "/admin",
        name: "Dashboard",
        component: () => import("../views/dashboard/Default.vue"),
        meta: {
            layout: 'Admin',
            title: 'Dashboard',
            middleware: [
                simpleAcl
            ]
        },
    },
    {
        path: "/login",
        name: "Login",
        component: () => import("../views/auth/Login.vue")
    },
    {
        path: "/unauthorized",
        name: "Unauthorized",
        component: () => import("../views/auth/Unauthorized.vue")
    },
    {
        path: "/:pathMatch(.*)*",
        name: "PageNotFound",
        component: () => import("../views/common/PageNotFound.vue")
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

function middlewarePipeline(context, middleware, index) {
    const nextMiddleware = middleware[index]

    if (!nextMiddleware) {
        return context.next
    }

    return () => {
        const nextPipeline = middlewarePipeline(
            context, middleware, index + 1
        )

        nextMiddleware({...context, next: nextPipeline})
    }
}

router.beforeEach((to, from, next) => {
    const auth = authStore()
    if (!auth.token && to.name != 'Login' && to.name.indexOf('admin') > 0) {
        next('/login');
    }

    if (!to.meta.middleware) {
        return next()
    }

    const middleware = to.meta.middleware
    const context = {
        to,
        from,
        next,
        auth
    }

    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })
});

export default router;
