import simpleAcl from '../../middleware/simpleAcl'

export default [
  {
    path: '/admin/publishers',
    name: 'Publisher',
    component: () => import("../../../views/dashboard/Router.vue"),
    meta: {
      middleware: [
        simpleAcl
      ]
    },
    children: [
      {
        path: '',
        name: 'IndexPublisher',
        component: () => import("../../../views/dashboard/publisher/Index.vue"),
      },
      {
        path: 'create',
        name: 'CreatePublisher',
        component: () => import("../../../views/dashboard/publisher/Add.vue"),
      },
      {
        path: 'edit/:id',
        name: 'EditPublisher',
        component: () => import("../../../views/dashboard/publisher/Edit.vue")
      },
    ],
  },
];
