export default {
  LOGIN: 'login',
  LOGIN_GOOGLE: 'login-with-provider',
  SEND_RESET_LINK_EMAIL: 'send-reset-link-email',
  RESET_PASSWORD: 'reset-password/{token}',
  COMMON: {
    constants: 'config/constants'
  },
  BOOK: {
    INDEX: 'books',
    GET_BOOKS_INTO_SECTION: 'books/get-books-into-section',
    GET_LIQUIDATION_BOOKS: 'books/get-liquidation-books',
    SEND_BORROWING_REQUEST: 'books/send-borrowing-request',
    UPDATE: 'books/{id}/update',
    DELETE: 'books/delete',
    LATEST: 'books/latest',
    SEARCH: 'books/search',
    FIND_ONE: 'books/find-one',
    GENERATE_QR_CODE: 'books/generate-qr-code',
    HOLDING: 'books/holding',
  },
  E_BOOK: {
    INDEX: 'e-books',
    LATEST: 'e-books/latest',
    SEARCH: 'e-books/search',
    FIND_ONE: 'e-books/find-one',
  },
  CLASS_NAME: {},
  GENRE: {
    ALL: 'genres/all',
    FIND_ONE: 'genres/find-one',
    GET_BOOKS: 'genres/get-books',
  },
  SECTION: {
    ALL: 'sections/all',
    FIND_ONE: 'sections/find-one',
    GET_BOOKS: 'sections/get-books',
  },
  MEMBER: {
    REQUEST_A_LIBRARY_CARD: 'members/request-a-library-card',
    REQUEST_A_BOOK: 'members/request-a-book',
    FIND_ONE: 'members/find-one',
    GET_ALL_CLASSES: 'members/get-all-classes',
  },
  SHOP: {
    ORDER: 'shop/order',
  },
  PROFILE: {
    UPDATE: 'profile/{id}/update',
  },
  CATEGORY: {
    FIND_ONE: 'categories/find-one',
  },
  POST: {
    INDEX: 'posts',
    FIND_ONE: 'posts/find-one',
  }
};
