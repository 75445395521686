export default function simpleAcl({to, next, auth}) {
    if (to.fullPath.indexOf('admin') && !auth.user) {
        return next({
            name: 'Home'
        })
    }

    const allowedRoutes = auth.user.staff_position.allowed_vue_router_routes.split(",");
    if (allowedRoutes.indexOf(to.name) > 0 || auth.user.staff_position.allowed_vue_router_routes == 'all_privileges') {
        return next()
    } else {
        return next({
            name: 'Unauthorized'
        })
    }
}