import simpleAcl from '../../middleware/simpleAcl'

export default [
  {
    path: '/admin/exams',
    name: 'Exam',
    component: () => import("../../../views/dashboard/Router.vue"),
    meta: {
      middleware: [
        simpleAcl
      ]
    },
    children: [
      {
        path: '',
        name: 'IndexExam',
        component: () => import("../../../views/dashboard/exam/Index.vue"),
      },
      {
        path: 'create',
        name: 'CreateExam',
        component: () => import("../../../views/dashboard/exam/Add.vue"),
      },
      {
        path: 'edit/:id',
        name: 'EditExam',
        component: () => import("../../../views/dashboard/exam/Edit.vue")
      },
    ],
  },
];
