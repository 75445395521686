export default {
    export(data, name) {
        const url = window.URL.createObjectURL(
            new Blob([data], {type: "application/csv"})
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name + `.xlsx`);

        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();
    },
    checkMinMax(el, min, max) {
        if (el.target.value > max) {
            el.preventDefault();
            return false;
        }

        if (el.target.value < min) {
            el.preventDefault();
            return false;
        }
        return el.target.value
    },
    numberToThousand(el) {
        let number = el.target.value.replace(/[^\d]/g, "");
        return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },
    displayToThousand(value) {
        if (value) return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

        return '0';
    },
    convertThousandToNumber(value) {
        if (typeof value === 'string' || value instanceof String) {
            return value.replace(/,/g, "");
        }

        return value;
    },
    displayStandardBookSize(standardBookSize) {
        if(standardBookSize)
            return standardBookSize.width + 'x' +  standardBookSize.height + '' + standardBookSize.unit_of_length;
        return '';
    },
    extractImageUrls(html) {
        var m,
          urls = [],
          rex = /<img.*?src="([^">]*\/([^">]*?))".*?>/g;

        while (m == rex.exec(html)) {
            urls.push(m[1]);
        }

        return urls;
    },
    truncateContent(html) {
        return html.substring(0, 1000) + '...';
    },
    printQrcodes(qrCodes) {
        if (qrCodes.length == 0) {
            alert('Bạn chưa tạo mã QR');
            return false;
        }

        var popupWindow = window.open('', 'PRINT');
        popupWindow.document.open();
        popupWindow.document.onreadystatechange = function () {
            if (this.readyState === 'complete') {
                this.onreadystatechange = function () {
                };

                setTimeout(function() {
                    popupWindow.focus();
                    popupWindow.print();
                }, 250);
            }
        }
        popupWindow.document.write('<html><head><title>' + document.title + '</title>');
        popupWindow.document.write('<style>text{font-size: 15px; font-weight: bold;} </style>');
        popupWindow.document.write('</head><body style="margin: 0;">');

        var html = '<table style="width: 110mm">';
        var fullRow = qrCodes.length / 3;
        var remain = 0;
        if (qrCodes.length % 3 != 0) {
            remain = qrCodes.length % 3;
            fullRow = (qrCodes.length - remain) / 3;
        }

        var cell1 = 0;
        var cell2 = 0;
        var cell3 = 0;
        for (var i = 0; i < fullRow; i = i + 1) {
            cell1 = (3*i);
            cell2 = (3*i) + 1;
            cell3 = (3*i) + 2;
            html += '<tr style="">';
            if (qrCodes[cell1]) {
                html += '<td style="width: 35mm;height: 22mm;vertical-align: middle; text-align:center;"><div style="margin: 0.5mm 0mm;">' + qrCodes[cell1].html + '</div><div style="width: 100%;font-size: 9pt; font-weight:bold;">' + qrCodes[cell1].code + '</div></td>';
            }
            if (qrCodes[cell2]) {
                html += '<td style="width: 35mm;height: 22mm;vertical-align: middle;text-align:center;"><div style="margin: 0.5mm 0mm;">' + qrCodes[cell2].html + '</div><div style="width: 100%;font-size: 9pt; font-weight:bold;">' + qrCodes[cell2].code + '</div></td>';
            }

            if (qrCodes[cell3]) {
                html += '<td style="width: 35mm;height: 22mm;vertical-align: middle; text-align:center;"><div style="margin: 0.5mm 0mm;">' + qrCodes[cell3].html + '</div><div style="width: 100%;font-size: 9pt; font-weight:bold;">' + qrCodes[cell3].code + '</div></td>';
            }
            html += '</tr>';
        }

        if (remain > 0) {
            var index = 0;
            html += '<tr>';
            for (var k = 0; k < 3; k = k + 1) {
                if (k < remain) {
                    index = (qrCodes.length - remain) + k;
                    if (qrCodes[index]) {
                        html += '<td style="width: 35mm;height: 22mm;vertical-align: middle;text-align:center"><div style="margin: 0.5mm 0mm;">' + qrCodes[index].html + '</div><div style="width: 100%;font-size: 9pt; font-weight:bold;">' + qrCodes[index].code + '</div></td>';
                    } else {
                        html += '<td style="width: 35mm;height: 22mm;vertical-align: middle; text-align:center">&nbsp;</td>';
                    }
                } else {
                    html += '<td style="width: 35mm;height: 22mm;vertical-align: middle; text-align:center">&nbsp;</td>';
                }
            }
            html += '</tr>';
        }
        html += '</table>';

        popupWindow.document.write(html);
        popupWindow.document.write('</body></html>');
        popupWindow.document.close(); // necessary for IE >= 10
    }
};
