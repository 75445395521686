import simpleAcl from '../../middleware/simpleAcl'

export default [
  {
    path: '/admin/staff-positions',
    name: 'StaffPosition',
    component: () => import("../../../views/dashboard/Router.vue"),
    meta: {
      middleware: [
        simpleAcl
      ]
    },
    children: [
      {
        path: '',
        name: 'IndexStaffPosition',
        component: () => import("../../../views/dashboard/staff-position/Index.vue"),
      },
      {
        path: 'create',
        name: 'CreateStaffPosition',
        component: () => import("../../../views/dashboard/staff-position/Add.vue"),
      },
      {
        path: 'edit/:id',
        name: 'EditStaffPosition',
        component: () => import("../../../views/dashboard/staff-position/Edit.vue")
      },
    ],
  },
];
  