import simpleAcl from '../../middleware/simpleAcl'

export default [
  {
    path: '/admin/statistics',
    name: 'Statistic',
    component: () => import("../../../views/dashboard/Router.vue"),
    meta: {
      middleware: [
        simpleAcl
      ]
    },
    children: [
      {
        path: '',
        name: 'IndexStatistic',
        component: () => import("../../../views/dashboard/statistic/Index.vue"),
      }
    ],
  },
];
