import simpleAcl from '../../middleware/simpleAcl'

export default [
    {
        path: '/admin/loan-requests',
        name: 'LoanRequest',
        component: () => import("../../../views/dashboard/Router.vue"),
        meta: {
            middleware: [
                simpleAcl
            ]
        },
        children: [
            {
                path: '',
                name: 'IndexLoanRequest',
                component: () => import("../../../views/dashboard/loan-request/Index.vue"),
            },
            {
                path: 'create',
                name: 'CreateLoanRequest',
                component: () => import("../../../views/dashboard/loan-request/Add.vue"),
            },
            {
                path: 'edit/:id',
                name: 'EditLoanRequest',
                component: () => import("../../../views/dashboard/loan-request/Edit.vue")
            },
        ],
    },
];
