import simpleAcl from '../../middleware/simpleAcl'

export default [
  {
    path: '/admin/orders',
    name: 'AdminOrder',
    component: () => import("../../../views/dashboard/Router.vue"),
    meta: {
      middleware: [
        simpleAcl
      ]
    },
    children: [
      {
        path: '',
        name: 'IndexOrder',
        component: () => import("../../../views/dashboard/order/Index.vue"),
      },
      {
        path: 'create',
        name: 'CreateOrder',
        component: () => import("../../../views/dashboard/order/Add.vue"),
      },
      {
        path: 'edit/:id',
        name: 'EditOrder',
        component: () => import("../../../views/dashboard/order/Edit.vue")
      },
      {
        path: 'view/:id',
        name: 'ShowOrder',
        component: () => import("../../../views/dashboard/order/View.vue"),
      },
    ],
  },
];
