import apiUrl from "../../configs/apiUrl";
import service from '@/services/service'
import {defineStore} from 'pinia'
//import router from "@/router";

export const authStore = defineStore({
    namespaced: true,
    id: 'auth',
    state: () => ({
        permissionList: [],
        token: null,
        user: null
    }),
    actions: {
        async login(params) {
            let url = apiUrl.LOGIN;
            const result = await service.post(url, params);
            if (result.statusCode && result.statusCode === 200) {
                this.token = result.data.data.token;
                this.user = result.data.data.user;
            }
            return result
        },
        async loginWithProvider(params) {
            let url = apiUrl.LOGIN_GOOGLE;
            const result = await service.post(url, params);
            if (result.statusCode && result.statusCode === 200) {
                this.token = result.data.data.token;
                this.user = result.data.data.user;
            }
            return result
        },
        definePermissionList(item) {
            this.permissionList = item;
        },
        async logout() {
            this.user = null;
            this.token = null;
            //router.push({name: 'Login'})
        },
        async setUser(user) {
            this.user = user;
        },
        async setLogin(token, user) {
            this.token = token;
            this.user = user;
        },
        async sendResetLinkEmail(params) {
            const url = apiUrl.SEND_RESET_LINK_EMAIL;
            return await service.post(url, params);
        },
        async resetPassword(params) {
            const url = apiUrl.RESET_PASSWORD.replace('{token}', params.token);
            return await service.post(url, params);
        },
    },

    getters: {
        isLogin() {
            return this.token !== null
        },
    },
    // persist: true,
    persist: true
})